import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import moment from "moment"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FromBlog from "../components/FromBlog"
import HeroBanner from "../components/BlogBanner"
import Stack from "../sdk/contentstack"
import init from "../modules/realtime"

export default function blogPost(props) {
  let [data, setData] = useState({ contentstackBlogPost: null, allContentstackPage: null, contentstackHeader: null, contentstackFooter: null })

  if (process.env.environment !== 'instant-preview') {
    data = props.data;
  }
  else {
    useEffect(async () => {
      const [indexPage, banner, header, footer] = await Promise.all([
        Stack.getEntryByUrl("blog_post", location.pathname, ["author", "related_post"]),
        Stack.getEntryByUrl("page", "/blog"),
        Stack.getEntry("header", "navigation_menu.page_reference"),
        Stack.getEntry("footer")
      ])

      setData({ contentstackBlogPost: indexPage[0], allContentstackPage: { nodes: banner }, contentstackHeader: header[0], contentstackFooter: footer[0] })
      init(indexPage[0].uid)
    }, [])
  }
  return (
    <Layout property={props} data={data}>
      <SEO title={data && data.contentstackBlogPost && data.contentstackBlogPost.title} />
      <HeroBanner data={data} />
      <div className="blog-container">
        <div className="blog-detail">
          <h2>
            {data && data.contentstackBlogPost && data.contentstackBlogPost.title
              ? data.contentstackBlogPost.title
              : ""}
          </h2>
          <p>
            {data && data.contentstackBlogPost && moment(data.contentstackBlogPost.date).format("ddd, MMM D YYYY")},{" "}
            <strong>{data && data.contentstackBlogPost && data.contentstackBlogPost.author && data.contentstackBlogPost.author[0].title}</strong>
          </p>
          {data && data.contentstackBlogPost && ReactHtmlParser(data.contentstackBlogPost.body)}
        </div>
        <div className="blog-column-right">
          <div className="related-post">
            <h2>
              Related Post
                </h2>
            <FromBlog
              data={
                data && data.contentstackBlogPost && data.contentstackBlogPost.related_post
                  ? data.contentstackBlogPost.related_post
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query($title: String!) {
    contentstackBlogPost(title: { eq: $title }) {
      url
      title
      body
      date
      author {
        title
        bio
        picture {
          url
          title
        }
      }
      related_post {
        body
        url
        title
        date
      }
      seo {
        enable_search_indexing
        keywords
        meta_description
        meta_title
      }
    }

    contentstackPage {
      page_components {
        widget {
          title_h2
          type
        }
      }
    }
  }
`
